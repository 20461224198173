import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "le-mariage-qui-vous-ressemble-à-100"
    }}>{`Le mariage qui vous ressemble à 100%`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "mariage nancy",
              "title": "mariage nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Mariage Nancy`}</strong></p>
    <h2 {...{
      "id": "un-lieu-magique-pour-un-mariage-inoubliable--sur-nancy"
    }}>{`Un lieu magique, pour un mariage inoubliable ! `}<strong parentName="h2">{`sur Nancy`}</strong></h2>
    <p>{`Situé dans entre Nancy et Metz, en pleine campagne, le château de Morey est un lieu exceptionnel pour organiser le plus beau jour de votre vie.
Un château XVIe pour décor avec un jardin aux arbres centenaires.
Dans un site merveilleusement préservé, le château de Morey se découvre au bout d’une majestueuse allée cavalière.`}</p>
    <h2 {...{
      "id": "idéal-si-vous-rechercher-unelocation-de-salle-pour-un-mariage"
    }}>{`Idéal si vous rechercher une location de salle pour un mariage`}</h2>
    <p>{`L’accueil des mariés et de leurs invités s’effectue par la grille d’honneur.
La salle de réception en pierre apparente, peut accueillir 65 convives au repas.
Une autre salle, dotée d’une jolie fresque, sera votre salle de danse.
Le soin apporté à la restauration des lieux en fait un cadre particulièrement raffiné.`}</p>
    <p>{`Équipements également à votre disposition:`}</p>
    <ul>
      <li parentName="ul">{`piste de danse`}</li>
      <li parentName="ul">{`cuisines pour traiteurs`}</li>
      <li parentName="ul">{`sanitaires`}</li>
      <li parentName="ul">{`Un petit salon, contigu à la salle d’honneur, représente l’endroit idéal pour les enfants.`}</li>
      <li parentName="ul">{`Tables et chaises à disposition`}</li>
    </ul>
    <h2 {...{
      "id": "votre-vin-dhonneur"
    }}>{`Votre vin d’honneur`}</h2>
    <p>{`Plusieurs possibilités vous sont proposées pour votre vin d’honneur : en plein air sous abri dans le parc du château par beau temps, ou dans les caves voutées du château en cas de pluie.
Dans le parc vous pourrez flâner, prendre de magnifiques photos et offrir à vos convives un véritable « voyage » au milieu des nombreux arbres centenaires. Accueil personnalisé. Chaque réception est unique. C’est pour cette raison qu’au château de Morey vous êtes accueillis personnellement.`}</p>
    <h2 {...{
      "id": "les-salles-du-château"
    }}>{`Les salles du château`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.15337423312883%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwH/2gAMAwEAAhADEAAAAV0o6DYSE06zN0azBxuf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMSIjP/2gAIAQEAAQUCvnGbDtTMhmjTIGLK+sFWahies//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQIBAT8BI3//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRECEicYH/2gAIAQEABj8CTZ6Mp9nF0aVii8xIY//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVGRocH/2gAIAQEAAT8hwA7k2IA2+4xLAqlOQyAXnqaAFVZMhsrGd5ebjL3PwnwseWf/2gAMAwEAAgADAAAAEETag//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQMBAT8QQ7Dk3b//xAAXEQEAAwAAAAAAAAAAAAAAAAABABAh/9oACAECAQE/EHUbf//EAB8QAQADAAEEAwAAAAAAAAAAAAEAESFBMVGx8JGh4f/aAAgBAQABPxDdymk6ofsOsgKl0NN5SCgoLZwN7QgIMtq+PMzrBRsXnYQzxRnrCaXhCjQiEQPNjPF5M9T2J95n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26a0712157345476aee40612230bac3d/f994e/mariage_chateau_salle.webp 163w", "/static/26a0712157345476aee40612230bac3d/bcd70/mariage_chateau_salle.webp 325w", "/static/26a0712157345476aee40612230bac3d/797b9/mariage_chateau_salle.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26a0712157345476aee40612230bac3d/367e5/mariage_chateau_salle.jpg 163w", "/static/26a0712157345476aee40612230bac3d/ab07c/mariage_chateau_salle.jpg 325w", "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg",
              "alt": "location de salles nancy",
              "title": "location de salles nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Location de salles nancy`}</strong></p>
    <p>{`Tarifs :
Pour les tarifs, veuillez prendre contact par mail, téléphone ou directement sur notre site internet.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Recevez votre devis</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      